@font-face {
  font-family: "GT Haptik";
  font-style: normal;
  font-weight: 100;
  font-display: block;
  src: url("/fonts/gt-haptik/GT-Haptik-Thin.woff2") format("woff2"),
    url("/fonts/gt-haptik/GT-Haptik-Thin.woff") format("woff");
}
@font-face {
  font-family: "GT Haptik";
  font-style: italic;
  font-weight: 100;
  font-display: block;
  src: url("/fonts/gt-haptik/GT-Haptik-Thin-Rotalic.woff2") format("woff2"),
    url("/fonts/gt-haptik/GT-Haptik-Thin-Rotalic.woff") format("woff");
}

@font-face {
  font-family: "GT Haptik";
  font-style: normal;
  font-weight: 300;
  font-display: block;
  src: url("/fonts/gt-haptik/GT-Haptik-Light.woff2") format("woff2"),
    url("/fonts/gt-haptik/GT-Haptik-Light.woff") format("woff");
}
@font-face {
  font-family: "GT Haptik";
  font-style: italic;
  font-weight: 300;
  font-display: block;
  src: url("/fonts/gt-haptik/GT-Haptik-Light-Rotalic.woff2") format("woff2"),
    url("/fonts/gt-haptik/GT-Haptik-Light-Rotalic.woff") format("woff");
}

@font-face {
  font-family: "GT Haptik";
  font-style: normal;
  font-weight: 400;
  font-display: block;
  src: url("/fonts/gt-haptik/GT-Haptik-Regular.woff2") format("woff2"),
    url("/fonts/gt-haptik/GT-Haptik-Regular.woff") format("woff");
}
@font-face {
  font-family: "GT Haptik";
  font-style: italic;
  font-weight: 400;
  font-display: block;
  src: url("/fonts/gt-haptik/GT-Haptik-Regular-Rotalic.woff2") format("woff2"),
    url("/fonts/gt-haptik/GT-Haptik-Regular-Rotalic.woff") format("woff");
}

@font-face {
  font-family: "GT Haptik";
  font-style: normal;
  font-weight: 500;
  font-display: block;
  src: url("/fonts/gt-haptik/GT-Haptik-Medium.woff2") format("woff2"),
    url("/fonts/gt-haptik/GT-Haptik-Medium.woff") format("woff");
}
@font-face {
  font-family: "GT Haptik";
  font-style: italic;
  font-weight: 500;
  font-display: block;
  src: url("/fonts/gt-haptik/GT-Haptik-Medium-Rotalic.woff2") format("woff2"),
    url("/fonts/gt-haptik/GT-Haptik-Medium-Rotalic.woff") format("woff");
}

@font-face {
  font-family: "GT Haptik";
  font-style: normal;
  font-weight: 700;
  font-display: block;
  src: url("/fonts/gt-haptik/GT-Haptik-Bold.woff2") format("woff2"),
    url("/fonts/gt-haptik/GT-Haptik-Bold.woff") format("woff");
}
@font-face {
  font-family: "GT Haptik";
  font-style: italic;
  font-weight: 700;
  font-display: block;
  src: url("/fonts/gt-haptik/GT-Haptik-Bold-Rotalic.woff2") format("woff2"),
    url("/fonts/gt-haptik/GT-Haptik-Bold-Rotalic.woff") format("woff");
}

@font-face {
  font-family: "GT Haptik";
  font-style: normal;
  font-weight: 900;
  font-display: block;
  src: url("/fonts/gt-haptik/GT-Haptik-Black.woff2") format("woff2"),
    url("/fonts/gt-haptik/GT-Haptik-Black.woff") format("woff");
}
@font-face {
  font-family: "GT Haptik";
  font-style: italic;
  font-weight: 900;
  font-display: block;
  src: url("/fonts/gt-haptik/GT-Haptik-Black-Rotalic.woff2") format("woff2"),
    url("/fonts/gt-haptik/GT-Haptik-Black-Rotalic.woff") format("woff");
}
